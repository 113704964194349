import * as React from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ViewColumnFilterData } from '../../../../models/DataRequestHub/DataTable';

function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field, options } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event: any) => {
        const eventValue = event.target.value as ViewColumnFilterData[];
        const newVaue = getValue(eventValue);
        apiRef.current.setEditCellValue({
            id,
            field,
            value: newVaue.filter((x: ViewColumnFilterData) => x.data !== ''),
        });
    };

    const getValue = (
        value: string | string[] | null | undefined | ViewColumnFilterData | ViewColumnFilterData[]
    ): ViewColumnFilterData[] => {
        if (Array.isArray(value)) {
            return value.map((item) => {
                if (typeof item === 'string') {
                    return { id: 0, data: item };
                } else {
                    return {
                        id: item.id,
                        data: item.data,
                    };
                }
            });
        }

        if (typeof value === 'string') {
            return [{ id: 0, data: value }];
        }

        return [];
    };

    return (
        <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={getValue(value)?.map(x=>x.data)}
            onChange={handleChange}
            sx={{ width: '100%' }}>
            {options.map((option: ViewColumnFilterData) => (
                <MenuItem key={option.data} value={option.data}>
                    {option.data}
                </MenuItem>
            ))}
        </Select>
    );
}

export const CustomDiscountEditCell = (
    params: GridRenderEditCellParams,
    options: ViewColumnFilterData[]
) => <CustomEditComponent {...params} options={options} />;
