export interface PracticeOption {
    option: string;
    free: string;
    paid: string;
}

export const independentPracticeOwnerText = "Complete access to the EcoSystem requires both a paid membership and " +
    "confirmation of your identity as an independent practice owner. Upgrading requires that you enter your credit card " +
    "information, with which we will charge the annual membership fee only once we have confirmed your identity as an " +
    "independent practice owner.";

export const corporatePracticeLeaderText = "Complete access to the EcoSystem requires both a paid membership and " +
    "confirmation of your identity as a corporate-owned practice leader. Upgrading requires that you enter your credit " +
    "card information, with which we will charge the annual membership fee only once we have confirmed your identity as " +
    "a corporate-owned practice leader.";

export const independentPracticeOwnerOptions: PracticeOption[] = [
    {
        option: 'Paid Version',
        free: 'Free',
        paid: '$99/Year'
    },
    {
        option: 'Announcements',
        free: 'Available',
        paid: 'Available'
    },
    {
        option: 'POC',
        free: 'See IPOC tile',
        paid: 'IPOC'
    },
    {
        option: 'Knowledge Library',
        free: 'Free Tier',
        paid: 'Standard VV Paid Content + Curated Ind Content'
    },
    {
        option: 'Affiliated Members',
        free: 'NA',
        paid: 'Available'
    },
    {
        option: 'Valuation Reports',
        free: 'F&E Available (1/Mo) Commission CAPVAR only',
        paid: 'Full Suite'
    },
    {
        option: 'PMGs / Services Rebates / Premium KL Content',
        free: '$399/Year',
        paid: '$299/Year'
    },
    {
        option: 'Current Brokerage Services',
        free: 'Available',
        paid: 'Available'
    },
    {
        option: 'DMs',
        free: 'NA',
        paid: 'Available Ind World'
    },
    {
        option: 'RPA / NP Giving / Gift Cards',
        free: 'Wallet Available',
        paid: 'Wallet Available'
    },
    {
        option: 'Frequency Discounts',
        free: 'NA',
        paid: 'Applicable'
    },
    {
        option: 'VV Connect Marketplace (Coming 2H2025)',
        free: 'Available',
        paid: 'Available'
    },
];

export const corporatePracticeLeaderOptions: PracticeOption[] = [
    {
        option: 'Paid Version',
        free: 'Free',
        paid: '$29/Month $299/Year'
    },
    {
        option: 'Announcements',
        free: 'Available',
        paid: 'Available'
    },
    {
        option: 'POC',
        free: 'See Corporate POC tile',
        paid: 'Corporate POC'
    },
    {
        option: 'Knowledge Library',
        free: 'Free Tier',
        paid: 'Standard VV Paid Content + Curated Corporate Content'
    },
    {
        option: 'Affiliated Members',
        free: 'NA',
        paid: 'Available'
    },
    {
        option: 'Valuation Reports',
        free: 'F&E Available (1/Mo) Commission CAPVAR only',
        paid: 'Full Suite'
    },
    {
        option: 'PMGs / Services Rebates / Premium KL Content',
        free: '$999/Year',
        paid: '$799/Year'
    },
    {
        option: 'Current Brokerage Services',
        free: 'NA',
        paid: 'NA'
    },
    {
        option: 'DMs',
        free: 'NA',
        paid: 'Available Corporate World'
    },
    {
        option: 'RPA / NP Giving / Gift Cards',
        free: 'Wallet Available',
        paid: 'Wallet Available'
    },
    {
        option: 'Frequency Discounts',
        free: 'NA',
        paid: 'Applicable'
    },
    {
        option: 'VV Connect Marketplace (Coming 2H2025)',
        free: 'NA',
        paid: 'NA'
    },
];
