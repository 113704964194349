import React, { useLayoutEffect, useRef } from 'react';
import { FormGroup, Input } from 'reactstrap';
import './NumericColumn.scss';
import {
    NumericFormatVariable,
    NumericFormatVariableNamesList,
} from '../../../../models/DataRequestHub/ProjectVariable';
import { CurrencyInput, Form } from '@availity/form';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';

interface NumericAnswerColumnInterface extends GridRenderEditCellParams {
    formType: string;
    isReadonly: boolean;
}

export const NumericColumn = (props: NumericAnswerColumnInterface) => {
    const { id, value, field, hasFocus, error } = props;

    const apiRef = useGridApiContext();
    const ref = useRef(null);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current?.focus();
        }
    }, [hasFocus]);

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
        });
    };

    const modalForm = useRef(null);

    const handleAccountingInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const eventTarget = event.target as HTMLTextAreaElement;
        const inputValue = eventTarget.value.replace('$', '').replace(',', '');

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (inputValue.startsWith('-')) {
                const positivePercentValue = inputValue.replace('-', '');
                handleValueChange(positivePercentValue.toString());
            } else {
                const negativePercentValue = `-${inputValue}`;
                handleValueChange(negativePercentValue.toString());
            }

            event.preventDefault();
        }
    };

    const renderAccountingField = (isReadonly: boolean) => {
        let valueToSet = '';
        if (value !== undefined && value !== null && value !== '') {
            valueToSet = value.toString().includes('.')
                ? value.toString()
                : value.toString() + '.00';

            if (valueToSet === '') {
                valueToSet = '0.00';
            }
        }

        return (
            <FormGroup>
                <CurrencyInput
                    ref={ref}
                    readOnly={isReadonly}
                    name="currencyAnswerField"
                    onKeyDown={handleAccountingInputKeyDown}
                    allowDecimals={true}
                    allowNegativeValue={true}
                    value={valueToSet}
                    prefix="$"
                    onValueChanged={(value) => {
                        handleValueChange(value ?? '');
                    }}
                />
            </FormGroup>
        );
    };

    const getDecimalPartOrZero = (x: any) =>
        x.toString().includes('.') ? x.toString().split('.').pop().length : 0;

    const handlePercentInputKeyDown = (
        event: any,
        keyprop: string,
        prefix: string
    ) => {
        const eventTarget = event.target as HTMLTextAreaElement;
        const inputValue = eventTarget.value;

        let isChangeAfterDot =
            eventTarget.selectionStart > keyprop.replace(prefix, '').length - 2;
        let isOneDigitAfterDot =
            getDecimalPartOrZero(keyprop.replace(prefix, '')) >= 1;
        if (
            !/[0-9]/.test(event.key) &&
            event.key != 'Backspace' &&
            event.key != 'ArrowRight' &&
            event.key != 'ArrowLeft' &&
            event.key != 'Tab' &&
            event.key != 'Delete' &&
            (event.key != '.' || keyprop.includes('.'))
        ) {
            event.preventDefault();
        }

        if (event.key == '.' && !isChangeAfterDot) {
            event.preventDefault();
        }

        if (/[0-9]/.test(event.key) && isOneDigitAfterDot && isChangeAfterDot) {
            event.preventDefault();
        }

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (inputValue.startsWith('-')) {
                const positivePercentValue = inputValue.replace('-', '');
                const formattedValue = positivePercentValue
                    .toString()
                    .replace('%', '');
                    
                handleValueChange(formattedValue);
            } else {
                const negativePercentValue = `-${inputValue}`;
                const formattedValue = negativePercentValue
                    .toString()
                    .replace('%', '');

                handleValueChange(formattedValue);
            }

            event.preventDefault();
        }
    };

    const renderPercentField = (isReadonly: boolean) => {
        let valueToSet = '';
        let valueWithoutPrefix = value?.replace('%', '');

        if (value !== undefined && value !== null && value !== '') {
            valueToSet = valueWithoutPrefix;

            if (
                (valueToSet.startsWith('0') &&
                    !valueToSet.startsWith('0.') &&
                    valueToSet != '0') ||
                (valueToSet.startsWith('-0') &&
                    !valueToSet.startsWith('-0.') &&
                    valueToSet != '-0')
            ) {
                valueToSet = valueToSet.replace(/^(-?)0/, '$1');
            }

            if (valueToSet === '') {
                valueToSet = '0';
            }
            valueToSet = valueToSet + '%';
        }

        return (
            <Input
                ref={ref}
                type="text"
                name="percentAnswerField"
                readOnly={isReadonly}
                value={valueToSet}
                onKeyDown={(event) => {
                    handlePercentInputKeyDown(event, valueWithoutPrefix, '%');
                }}
                onChange={(event) => {
                    const value = event.target.value.replace('%', '');
                    handleValueChange(value);
                }}></Input>
        );
    };

    const handleDecimalInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const eventTarget = event.target as HTMLTextAreaElement;
        const numberAnswer = Number(eventTarget.value);

        if (event?.key === 'e' || event?.key === '+') {
            event.preventDefault();
        }

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (numberAnswer) {
                handleValueChange((-numberAnswer).toString());
            }

            event.preventDefault();
        }
    };

    const renderDecimalField = (isReadonly: boolean) => {
        const numberValue = Number(value);
        const isInvalid = Number.isNaN(numberValue);

        return (
            <Input
                ref={ref}
                readOnly={isReadonly}
                type="number"
                onKeyDown={handleDecimalInputKeyDown}
                invalid={isInvalid}
                value={value}
                onChange={(event) =>
                    handleValueChange(event.target.value)
                }></Input>
        );
    };

    const handleIntegerInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'e' || event.key === '.' || event.key === '+') {
            event.preventDefault();
        }

        const eventTarget = event.target as any;
        const numberAnswer = Number(eventTarget.value);

        if (eventTarget.value.length === 0 && event?.key === '-') {
            eventTarget.value = '';
        }

        if (eventTarget.value.length > 0 && event?.key === '-') {
            if (numberAnswer) {
                handleValueChange((-numberAnswer).toString());
            }

            event.preventDefault();
        }
    };

    const renderIntegerField = (isReadonly: boolean) => {
        const numberValue = Number(value);
        const isInvalid = Number.isNaN(numberValue);

        return (
            <Input
                ref={ref}
                readOnly={isReadonly}
                type="number"
                pattern="-?[0-9]*"
                invalid={isInvalid}
                onKeyDown={handleIntegerInputKeyDown}
                value={value}
                onChange={(event) =>
                    handleValueChange(event.target.value)
                }></Input>
        );
    };

    const renderInputField = (isAdminView: boolean) => {
        switch (props.formType) {
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Accounting
            ).name:
                return renderAccountingField(isAdminView);
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Percent
            ).name:
                return renderPercentField(isAdminView);
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Decimal
            ).name:
                return renderDecimalField(isAdminView);
            case NumericFormatVariableNamesList.find(
                (x) => x.id === NumericFormatVariable.Integer
            ).name:
                return renderIntegerField(isAdminView);
            default:
                return renderDecimalField(isAdminView);
        }
    };

    return (
        <Form innerRef={modalForm} onSubmit={() => {}} initialValues={{}}>
            <div
                className={
                    error
                        ? `Mui-error numeric-column-input`
                        : `numeric-column-input`
                }>
                {renderInputField(props.isAdminView || props.isReadonly)}
            </div>
        </Form>
    );
};
