import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../../../models/DataRequestHub/DataRequestProject';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { DesignModeSwitcher } from '../../../../DataRequest/DesignModeSwitcher/DesignModeSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { TableSettingsPopup } from '../../Popups/TableSettingsPopup/TableSettingsPopup';
import DataTableEditor from './DataTableEditor';
import {
    DataTable,
    DataTableColumn,
    DataTableView,
    emptyDataTable,
} from '../../../../../models/DataRequestHub/DataTable';
import DataTableStagesList from '../../../../DataRequest/DataTableStagesList/DataTableStagesList';
import {
    removeFormListIfNeeded,
    removeFormTypeIfNeeded,
    validateDataTableViews,
    validateProjectDataTables,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import { ProjectVariable } from '../../../../../models/DataRequestHub/ProjectVariable';
import { EditorModeEnum } from '../../../../../models/DataRequestHub/ProjectEditorModeEnum';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';

interface DataTableEditorHostProps {
    editMode: EditorModeEnum;
    dataTables: DataTable[];
    setDataTables(forms: DataTable[]): void;
    setDataTableViews(views: DataTableView[]): void;
    isLoading: boolean;
    updateProjectData(): void;
    updateProjectVariables(): void;
    project: DataRequestProject;
    isAdminView: boolean;
    handleCreateViewClick(tableId: number): void;
    handleEditViewClick(tableId: number, viewId: number): void;
    answerType: ProjectVariable;
    formType: ProjectVariable;
    numericFormat: ProjectVariable;
    notSystemVariables: ProjectVariable[];
}

const DataTableEditorHost = forwardRef(
    (props: DataTableEditorHostProps, ref) => {
        const editorRef = useRef(null);
        const [selectedTableId, setSelectedTableId] = useState(0);
        const [invalidTableIds, setInvalidTableIds] = useState<number[]>([]);
        const [invalidTableViewIds, setInvalidTableViewIds] = useState<
            number[]
        >([]);
        const [isTableSettingsPopupVisible, setIsTableSettingsPopupVisible] =
            useState(false);
        const formNameRef = useRef(null);
        const location = useLocation();
        const navigate = useNavigate();

        useEffect(() => {
            if (isTemplateEditor) {
                setSelectedTableId(props.dataTables[0].id);
            }
        }, [props, selectedTableId]);

        useEffect(() => {
            if (location.state.dataTableEditorParams) {
                const preselectedTableId =
                    location.state.dataTableEditorParams.targetTableId ?? 0;
                const isTableExists = props.dataTables.some(
                    (s) => s.id === preselectedTableId
                );

                if (isTableExists) {
                    setSelectedTableId(preselectedTableId);
                }
            }
        }, []);

        useEffect(() => {
            if (!isTemplateEditor) {
                validateProjectDataTables(props.dataTables, setInvalidTableIds);
                validateDataTableViews(
                    props.dataTables,
                    setInvalidTableViewIds
                );
            }
        }, [props.dataTables]);

        const isTemplateEditor = props.editMode !== EditorModeEnum.EditProject;

        useImperativeHandle(ref, () => ({
            addRow() {
                editorRef.current.addRow();
            },
            validateAllRows() {
                let isFormValid = true;
                if (isTemplateEditor || selectedTableId) {
                    isFormValid = editorRef.current.validateAllRows();
                }
                const areTablesValid = validateProjectDataTables(
                    props.dataTables,
                    setInvalidTableIds
                );
                const areViewsValid = validateDataTableViews(
                    props.dataTables,
                    setInvalidTableViewIds
                );
                return isFormValid && areViewsValid && areTablesValid;
            },
        }));

        const getSelectedDataTable = () =>
            props.dataTables.find((table) => table.id === selectedTableId) ??
            emptyDataTable;

        const getSelectedTableColumns = () => [
            ...(props.dataTables.find((table) => table.id === selectedTableId)
                ?.columns ?? []),
        ];

        const updateSelectedTableColumns = (columns: DataTableColumn[]) => {
            const targetTable = props.dataTables.find(
                (table) => table.id === selectedTableId
            );
            const targetTableIndex = props.dataTables.indexOf(targetTable);
            targetTable.columns = columns;
            targetTable.columns = removeFormTypeIfNeeded(targetTable.columns);
            targetTable.columns = removeFormListIfNeeded(targetTable.columns);
            const updatedTables = [...props.dataTables];
            updatedTables[targetTableIndex] = targetTable;
            props.setDataTables(updatedTables);
        };

        useLayoutEffect(() => {
            if (formNameRef?.current) {
                formNameRef.current.setFieldTouched('tableName', true, true);
            }
        }, [formNameRef.current]);

        const turnOnTablesList = () => {
            setSelectedTableId(null);
        };

        const turnOnDataTableEditor = (tableId: number) => {
            setSelectedTableId(tableId);
        };

        const renderEditor = () => {
            const table = getSelectedDataTable();

            return (
                <>
                    {isTemplateEditor ? (
                        <></>
                    ) : (
                        <>
                            <div className="stage-navigation-breadcrumb">
                                <label
                                    className="link-text"
                                    onClick={() => turnOnTablesList()}>
                                    Table
                                </label>
                                <label className="node-separator">
                                    {' / '}
                                </label>
                                <label className="link-text">
                                    {table.name}
                                </label>
                            </div>
                            <div className="editor-header">
                                <Col>
                                    <Row className="header-row">
                                        <Label>
                                            <strong>
                                                {'Original Template Name: '}
                                            </strong>
                                            {table.originalTemplateName}
                                        </Label>
                                        <Button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => {
                                                editorRef?.current.addRow();
                                            }}>
                                            {'+ Add New Row'}
                                        </Button>
                                    </Row>
                                </Col>
                                <div className="project-settings-section">
                                    <div
                                        className="clickable"
                                        onClick={() => {
                                            setIsTableSettingsPopupVisible(
                                                true
                                            );
                                        }}>
                                        <FontAwesomeIcon icon={faWrench} />{' '}
                                        {'User View Settings'}
                                    </div>
                                    {props.project.state ===
                                        DataRequestProjectState.Active && (
                                        <DesignModeSwitcher
                                            formId={0}
                                            defaultValue={true}
                                            onDisable={() => {
                                                navigate(
                                                    `/data-request-dashboard?tab=${
                                                        EditorTypeEnum.DataTable +
                                                        1
                                                    }`,
                                                    {
                                                        state: {
                                                            preselectedTableId:
                                                                selectedTableId,
                                                            projectId:
                                                                props.project
                                                                    .id,
                                                            isAdmin: true,
                                                            projectName:
                                                                props.project
                                                                    .name,
                                                            practiceName:
                                                                props.project
                                                                    .practiceName,
                                                        },
                                                    }
                                                );
                                            }}></DesignModeSwitcher>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    <DataTableEditor
                        tableColumns={getSelectedTableColumns()}
                        setTableColumns={updateSelectedTableColumns}
                        isLoading={props.isLoading}
                        answerType={props.answerType}
                        formType={props.formType}
                        numericFormat={props.numericFormat}
                        notSystemVariables={props.notSystemVariables}
                        ref={editorRef}
                    />
                </>
            );
        };

        const renderStageList = () => (
            <DataTableStagesList
                isAdminView={props.isAdminView}
                isTemplateEditorView={true}
                projects={[props.project]}
                openSettings={() => {
                    setIsTableSettingsPopupVisible(true);
                }}
                isEditorMode={true}
                updateProjectsData={props.updateProjectData}
                onAddNewDataTableViewClick={props.handleCreateViewClick}
                onTableNameClick={(tableId) => {
                    turnOnDataTableEditor(tableId);
                }}
                onTableViewNameClick={(tableId, viewId) =>
                    props.handleEditViewClick(tableId, viewId)
                }
                invalidTableStageIds={invalidTableIds}
                invalidTableViewIds={invalidTableViewIds}
            />
        );

        return (
            <>
                {isTemplateEditor
                    ? renderEditor()
                    : selectedTableId
                    ? renderEditor()
                    : renderStageList()}

                {isTableSettingsPopupVisible && (
                    <TableSettingsPopup
                        isVisible={isTableSettingsPopupVisible}
                        setIsVisible={setIsTableSettingsPopupVisible}
                        onSubmit={() => {
                            props.updateProjectData();
                            props.updateProjectVariables();
                        }}
                        projectId={props.project.id}
                        dataTables={props.project.dataTables}
                        dataForms={
                            props.project.dataForms
                        }></TableSettingsPopup>
                )}
            </>
        );
    }
);
export default DataTableEditorHost;
