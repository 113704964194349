import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './info-tooltip.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import sanitizeHtml from 'sanitize-html';

interface InfoTooltipProps {
    text: string;
    idText?: string;
    place?: 'right' | 'top' | 'bottom' | 'left';
    html?: string;
    isOpaque?: boolean;
    className?: string;
}

const InfoTooltip = (props: InfoTooltipProps) => {
    const key =
        props.idText !== undefined ? props.idText : Date.now().toString();
    const uniqueId = 'info-tooltip-' + key;
    const place = props.place ?? 'top';
    return (
        <span className={`info-tooltip ${props.className ?? ''}`}>
            <FontAwesomeIcon
                id={uniqueId}
                icon={faInfoCircle}
                data-tooltip-html={
                    props.html ? props.html : sanitizeHtml(props.text)
                }
                className="tooltip-icon"
            />
            <Tooltip
                place={place}
                className={
                    'tooltip-content place-' +
                    place +
                    (props.isOpaque ? ' opaque' : '')
                }
                anchorId={uniqueId}
                classNameArrow="arrow"
                offset={15}
            />
        </span>
    );
};
export default InfoTooltip;
