import React, { useEffect, useState } from 'react';
import './DocumentsUploadPopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import { ModalBody, ModalHeader } from 'reactstrap';
import BootstrapTable from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import SortingOption, {
    SortOrder,
} from '../../../components/Table/models/bootstrap-table-sorting-option';
import { sortingFunction } from '../../../components/Table/models/table-helper';
import moment from 'moment';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import FileUploadsList from '../../../components/FileUploadsList/FileUploadsList';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import AnswerFile from '../../../models/DataRequestHub/AnswerFile';

interface DocumentsUploadPopupProps {
    header: string;
    uploadedFiles: AnswerFile[];
    close(): void;
    projectId: number;
    formId: number;
    questionId: number;
    onSuccessfulUpload(): void;
    isAdminView: boolean;
    allowedExtensions: string[];
}
interface TableSortingState {
    data: any[];
    sortField: string;
    sortOrder: SortOrder;
}

const DocumentsUploadPopup = (props: DocumentsUploadPopupProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<AnswerFile[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>();

    const defaultSorted: SortingOption = {
        dataField: 'dateCreated',
        order: 'desc',
    };
    const [sortingOption, setSortingOption] =
        useState<SortingOption>(defaultSorted);

    const getColumns = () => [
        {
            dataField: 'id',
            text: '',
            hidden: true,
        },
        {
            dataField: 'name',
            text: 'File',
            sort: true,
            style: {
                width: '80%',
            },
            formatter: (cellContent: string, row: AnswerFile) => (
                <div className="link-text" onClick={() => openFile(row.id)}>
                    {cellContent}
                </div>
            ),
        },
        {
            dataField: 'dateCreated',
            text: 'Upload Date',
            sort: true,
            style: {
                width: '20%',
            },
            formatter: (cellContent: Date) => moment(cellContent).format('L'),
        },
    ];

    useEffect(() => {
        sortFunction(props.uploadedFiles ?? [], sortingOption);
    }, [props.uploadedFiles]);

    const handleTableChange = (_props: any, state: TableSortingState) => {
        const newSortingOption: SortingOption = {
            dataField: state.sortField,
            order: state.sortOrder as SortOrder,
        };
        setSortingOption(newSortingOption);
        sortFunction(state.data, newSortingOption);
    };

    const sortFunction = (data: any[], sortingOption: SortingOption) => {
        const sortedQuestions = sortingFunction(data, sortingOption);
        setTableData(sortedQuestions);
    };

    const deleteFile = (file: File) => {
        if (!isLoading) {
            const index = selectedFiles.indexOf(file);
            if (index > -1) {
                selectedFiles.splice(index, 1);
                setSelectedFiles([...selectedFiles]);
            }
        }
    };

    const uploadFiles = async (files: File[]) => {
        const formFiles = files.map((f) => {
            const formData = new FormData();
            formData.append('File', f);
            return formData;
        });

        const requests = formFiles.map((f) =>
            axios.post(`/api/temporaryfiles`, f, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        );

        const responses = await Promise.all(requests);

        if (responses.some((r) => r.status != 200)) {
            createNotification(
                'Some files are not uploaded due to an unexpected error.',
                'error'
            );
            setIsLoading(false);
            throw new Error('An error occurent while file uploading');
        }

        return responses.map((m) => {
            return m.data as AnswerFile;
        });
    };

    const saveUploads = async () => {
        setIsLoading(true);
        const uploadedFiles = await uploadFiles(selectedFiles);
        const fileIds = uploadedFiles.map((m) => m.id);

        axios
            .post(
                `/api/dataRequestProject/${props.projectId}/dataRequestForm/${props.formId}/question/${props.questionId}/answer/answerFiles`,
                {
                    fileIds,
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    const uploadedFilesCount = response.data;

                    if (uploadedFilesCount !== fileIds.length) {
                        createNotification(
                            `Some of files doesn't match allowed extensions. Count: ${
                                fileIds.length - uploadedFilesCount
                            }`,
                            'warning'
                        );
                    }

                    props.onSuccessfulUpload();
                    setSelectedFiles([]);
                } else {
                    createNotification('An error occured', 'error');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const validateFilesSelection = (files: File[]) => {
        if (
            props.allowedExtensions.some((s) => s.toLocaleLowerCase() === 'any')
        ) {
            return files;
        }

        const allowedFiles = files.filter((f) =>
            props.allowedExtensions.some((s) => f.name.endsWith(s))
        );

        if (files.length > allowedFiles.length) {
            const notMathedFilesCount = files.length - allowedFiles.length;
            createNotification(
                `Some of files doesn't match allowed extensions. Count: ${notMathedFilesCount}`,
                'warning'
            );
        }

        return allowedFiles;
    };

    const handleFileSelection = (fileList: FileList) => {
        let files = Array.from(fileList).map((m) => m);
        files = validateFilesSelection(files);

        const newFiles = files.filter(
            (f) =>
                !selectedFiles?.some(
                    (s) =>
                        s.name.toLocaleLowerCase() ===
                        f.name.toLocaleLowerCase()
                ) ?? true
        );

        setSelectedFiles(selectedFiles?.concat(newFiles) ?? newFiles);
    };

    const openFile = (fileId: number) => {
        axios
            .get(
                `/api/dataRequestProject/${props.projectId}/dataRequestForm/${props.formId}/question/${props.questionId}/answer/answerFiles/${fileId}`
            )
            .then((response) => {
                window.open(response.data, '_blank');
            });
    };

    const addMoreDocuments = () => document.getElementById('fileInput').click();

    return (
        <div className="documents-upload-popup">
            <Rodal
                visible={true}
                onClose={() => {
                    if (!isLoading) {
                        props.close();
                    }
                }}
                width={600}>
                <ModalHeader>{props.header}</ModalHeader>
                <ModalBody>
                    <div className="uploaded-documents-section">
                        <label className="section-title">
                            Uploaded Documents
                        </label>
                        <div className="document-uploads-table">
                            <BootstrapTable
                                id="document-uploads-table"
                                striped
                                bootstrap4
                                remote
                                keyField="id"
                                data={tableData}
                                columns={getColumns()}
                                defaultSorted={[defaultSorted]}
                                onTableChange={handleTableChange}
                                noDataIndication="No Data available"></BootstrapTable>
                        </div>
                    </div>
                    <div className="new-documents-section">
                        <label className="section-title">New Uploads</label>
                        <input
                            hidden
                            type="file"
                            id="fileInput"
                            onChange={(a) => {
                                handleFileSelection(a.target.files);
                                a.target.value = null;
                            }}
                            multiple
                            accept={props.allowedExtensions.join(',')}
                        />
                        <div className="uploads">
                            {selectedFiles ? (
                                <FileUploadsList
                                    fileList={selectedFiles}
                                    onDeleteClick={
                                        deleteFile
                                    }></FileUploadsList>
                            ) : (
                                []
                            )}
                        </div>
                        <button
                            className="btn btn-primary"
                            disabled={isLoading}
                            onClick={addMoreDocuments}>
                            Add More Documents
                        </button>
                    </div>
                    <div className="buttons-wrapper ">
                        <button
                            disabled={isLoading}
                            className="btn btn-cancel"
                            onClick={props.close}>
                            {props.isAdminView ? 'Close' : 'Cancel'}
                        </button>
                        <ButtonLoader
                            buttonText={'Confirm & Upload'}
                            loaderButtonText={''}
                            disabled={
                                isLoading ||
                                selectedFiles?.length === 0 ||
                                !selectedFiles
                            }
                            isLoading={isLoading}
                            onClick={saveUploads}
                            className={'btn btn-primary'}
                        />
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};

export default DocumentsUploadPopup;
