import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import './poster-directory-modal.scss';
import {
    Box,
    Paper,
    SortDirection,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
} from '@mui/material';
import { Discussion } from '../models/discussion';
import { OnlineState } from '../models/online-state';
import { Member } from '../models/member';
import { Poster } from '../models/poster';
import usersGroupIcon from '../../assets/images/userGroups.png';
import { Link } from 'react-router-dom';
import { Practice } from '../models/practice';
import PocProfileModal from '../poc-profile-modal/poc-profile-modal';
import { USER_ROLES } from '../../utils/constants';
import Helpers from '../../utils/helper';
import { StateAbbreviationService } from '../../utils/stateAbbreviationHelper';
import { DirectButton } from '../../direct/direct-button/direct-button';
import { useStateSelector } from '../../store/selectors';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PocAccessManagingToolsModal from '../../containers/Admin/Permissions/Users/Modal/PocAccessManagingToolsModal';
import createNotification from '../../utils/createNotification';
import { UserAccessRights } from '../models/userAccessRights';

interface PosterDirectoryModalProps {
    isVisible: boolean;
    setVisibility(isVisible: boolean): void;
    discussion?: Discussion;
    discussions: Array<Discussion>;
    members: Array<Member>;
    isIPOC: boolean;
}

interface PostersDirectoryMember {
    id: number;
    fullName: string;
    countOfPosts: number;
    graduationYear: number;
    veterinarySchool: string;
    foregroundColorCode: string;
    initials: string;
    backgroundColorCode: string;
    onlineState: OnlineState;
    practices: Array<Practice>;
    avatarPath: string;
    userId: number;
    isActive: boolean;
}

const PosterDirectoryModal = (props: PosterDirectoryModalProps) => {
    const axios = useStateSelector((state: any) => state.core.axios);
    const [order, setOrder] = useState('asc');
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('fullName');
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedMemberToShowInfoId, setSelectedMemberToShowInfoId] =
        useState(0);
    const [isPocProfileModalVisible, setIsPocProfileModalVisible] =
        useState(false);
    const [isUserAccessRightsModalVisible, setIsUserAccessRightsModalVisible] =
        useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedUserAccessRights, setSelectedUserAccessRights] =
        useState<UserAccessRights>(null);
    const [selectedMemberUserId, setSelectedMemberUserId] = useState(0);

    useEffect(() => {
        const summary = JSON.parse(localStorage.getItem('loggedInUserSummary'));
        const isAdmin = summary?.roleId === USER_ROLES.ADMIN;
        setIsAdmin(isAdmin);
    }, []);

    const toggleModalVisibility = () => {
        props.setVisibility(!props.isVisible);
        setSearch('');
    };

    const MapPosterDirectoryMember = (
        countOfPosts: number,
        member: Member
    ): PostersDirectoryMember => ({
        id: member.id,
        fullName: member.fullName,
        countOfPosts: countOfPosts,
        graduationYear: member.profile?.graduationYear,
        veterinarySchool: member.profile?.veterinarySchool,
        backgroundColorCode: member.backgroundColorCode,
        foregroundColorCode: member.foregroundColorCode,
        initials: member.initials,
        onlineState: member.onlineState,
        practices: member.profile?.practices,
        avatarPath: member.profile?.avatarPath,
        userId: member.userId,
        isActive: member.isActive && member.profile?.isActive,
    });
    let posters: Array<PostersDirectoryMember> = [];

    if (!props.discussion) {
        props.members
            .filter((f) => f.isActive)
            .forEach((member) => {
                let countOfPosts = 0;
                props.discussions.forEach((discussion) => {
                    let poster = discussion.posters.find(
                        (poster) => poster && poster.id === member.id
                    );

                    if (poster) {
                        countOfPosts += poster.countOfPosts;
                    }
                });
                posters.push(MapPosterDirectoryMember(countOfPosts, member));
            });
    } else {
        props.members
            .filter((f) => f.isActive)
            .forEach((member) => {
                props.discussion.posters.forEach((poster: Poster) => {
                    if (poster && poster.id === member.id) {
                        posters.push(
                            MapPosterDirectoryMember(
                                poster.countOfPosts,
                                member
                            )
                        );
                    }
                });
            });
    }

    const headCells = [
        {
            id: 'fullName',
            numeric: false,
            label: 'Name',
            sortable: true,
        },
        {
            id: 'practices',
            numeric: false,
            label: 'Practice(s)',
            sortable: true,
        },
        {
            id: 'graduationYear',
            numeric: false,
            label: 'Vet School, Year',
            sortable: true,
        },
        {
            id: 'countOfPosts',
            numeric: true,
            label: '# of Posts',
            sortable: true,
        },
        {
            id: 'action',
            numeric: false,
            label: 'Action',
            sortable: false,
            isOnlyForAdmin: true,
        },
        {
            id: 'userId',
            numeric: false,
            label: '',
            sortable: false,
        },
    ];

    const getHeadCells = () => {
        if (!isAdmin) {
            return headCells.filter((hc) => !hc.isOnlyForAdmin);
        }
        return headCells;
    };

    const handleSearch = (event: any) => {
        setSearch(event.target.value.toLowerCase());
    };
    const data = {
        directoryMembers: posters
            .filter(
                (item, index, array) =>
                    array.findIndex((f) => f.id === item.id) === index
            )
            .filter((poster) => poster.fullName.toLowerCase().includes(search)),
    };

    const editUserAccessRights = (directoryMember: PostersDirectoryMember) => {
        setSelectedMemberUserId(directoryMember.userId);
        getUserAccessRights(directoryMember.userId).then((response) => {
            setSelectedUserAccessRights(response.data);
            setLoading(false);
        });
        setIsUserAccessRightsModalVisible(true);
    };

    const getUserAccessRights = async (userId: number) => {
        setLoading(true);
        return await axios.get(`api/users/AccessRights`, {
            params: { userId: userId },
        });
    };

    const updateUserAccessRights = (userPocAccessRights: number) => {
        const body = {
            userPocAccessRights: userPocAccessRights,
            doesHaveAccessToKb: selectedUserAccessRights.doesHaveAccessToKb,
        };
        return axios
            .put(`/api/users/${selectedMemberUserId}/AccessRights`, body)
            .then(() => {
                createNotification(
                    'Access rights updated successfully',
                    'success'
                );
                onClose();
            })
            .catch(() => {
                createNotification('Something went wrong', 'error');
            });
    };

    const handleRequestSort = (property: any) => (_event: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const comparator = (left: any, right: any) => {
        if (left > right) return 1;
        if (left < right) return -1;
        return 0;
    };

    const stableSort = (posters: PostersDirectoryMember[]) => {
        const sortedArray = posters;
        sortedArray.sort((a, b) => {
            const propName = orderBy as keyof PostersDirectoryMember;

            if (propName === 'practices') {
                return comparator(a[propName][0].name, b[propName][0].name);
            }
            if (propName === 'graduationYear') {
                const compResult = comparator(
                    a.veterinarySchool,
                    b.veterinarySchool
                );
                if (!compResult) {
                    return comparator(a[propName], b[propName]);
                }

                return compResult;
            } else {
                return comparator(a[propName], b[propName]);
            }
        });

        const isAsc = order === 'asc';
        if (!isAsc) {
            sortedArray.reverse();
        }

        return sortedArray;
    };

    const getSelectedMember = () => {
        return props.members.find(
            (m: Member) => m.id == selectedMemberToShowInfoId
        );
    };

    const currentMemberId = useStateSelector((state) => {
        return state.clubhouse?.clubhouse?.currentMemberId ?? 0;
    });

    const onClose = () => {
        setIsUserAccessRightsModalVisible(false);
    };

    const openMemberPocProfileModal = (memberId: number) => {
        setSelectedMemberToShowInfoId(memberId);
        setIsPocProfileModalVisible(true);
    };

    return (
        <div className="posterDirectoryModal">
            <Modal
                className="posterDirectoryTablePopUp"
                isOpen={props.isVisible}
                toggle={toggleModalVisibility}>
                <div className="create-new-discussion-popup-header">
                    <ModalHeader toggle={toggleModalVisibility}>
                        Poster Directory -{' '}
                        {props.discussion
                            ? `${props.discussion.name}`
                            : `${props.isIPOC ? 'I' : ''}POC Members`}
                    </ModalHeader>
                </div>
                <ModalBody className="editTitleBody deleteModalBody profileViewPopUpBody">
                    <div className="serachBar">
                        <img src={usersGroupIcon} />
                        <input
                            type="text"
                            placeholder="Search Directory"
                            onChange={handleSearch}
                        />
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Paper>
                            <Toolbar>
                                {selected.length > 0 ? (
                                    <Typography
                                        color="inherit"
                                        variant="subtitle1"
                                        component="div">
                                        {selected.length} selected
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="h6"
                                        id="tableTitle"
                                        component="div">
                                        Nutrition
                                    </Typography>
                                )}
                            </Toolbar>
                            <TableContainer>
                                <Table
                                    className="posterDirectorTable"
                                    sx={{ minWidth: 450 }}
                                    aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            {getHeadCells().map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={
                                                        headCell.numeric
                                                            ? 'center'
                                                            : 'left'
                                                    }
                                                    sortDirection={
                                                        orderBy === headCell.id
                                                            ? (order as SortDirection)
                                                            : false
                                                    }>
                                                    {headCell.sortable ? (
                                                        <TableSortLabel
                                                            active={
                                                                orderBy ===
                                                                headCell.id
                                                            }
                                                            direction={
                                                                orderBy ===
                                                                headCell.id
                                                                    ? (order as
                                                                          | 'asc'
                                                                          | 'desc')
                                                                    : 'asc'
                                                            }
                                                            onClick={handleRequestSort(
                                                                headCell.id
                                                            )}>
                                                            {headCell.label}
                                                            {orderBy ===
                                                            headCell.id ? (
                                                                <Box component="span">
                                                                    {order ===
                                                                    'desc'
                                                                        ? ''
                                                                        : ''}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <span className="MuiButtonBase-root MuiTableSortLabel-root css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root">
                                                            {headCell.label}
                                                        </span>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {(!data.directoryMembers ||
                                            data.directoryMembers.length ===
                                                0) && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={12}
                                                    align="center">
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {data.directoryMembers &&
                                            stableSort(
                                                data.directoryMembers
                                            ).map((directoryMember, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={
                                                            directoryMember.fullName
                                                        }>
                                                        <TableCell
                                                            id={labelId}
                                                            padding="none">
                                                            {directoryMember.avatarPath ? (
                                                                <div
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        openMemberPocProfileModal(
                                                                            directoryMember.id
                                                                        );
                                                                        e.stopPropagation();
                                                                    }}
                                                                    className="table-avatar-icon">
                                                                    <span className="clientLogin">
                                                                        <img
                                                                            src={
                                                                                directoryMember.avatarPath
                                                                            }
                                                                            className="poster-member-icon"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        openMemberPocProfileModal(
                                                                            directoryMember.id
                                                                        );
                                                                        e.stopPropagation();
                                                                    }}
                                                                    className="poster-member-icon"
                                                                    style={{
                                                                        background:
                                                                            directoryMember.backgroundColorCode,
                                                                    }}>
                                                                    <div
                                                                        className="poster-member-initials"
                                                                        style={{
                                                                            color: directoryMember.foregroundColorCode,
                                                                        }}>
                                                                        {
                                                                            directoryMember.initials
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <a
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    openMemberPocProfileModal(
                                                                        directoryMember.id
                                                                    );
                                                                    e.stopPropagation();
                                                                }}>
                                                                {
                                                                    directoryMember.fullName
                                                                }
                                                            </a>

                                                            {directoryMember.onlineState ==
                                                                OnlineState.Offline && (
                                                                <span className="onlineDot"></span>
                                                            )}
                                                            {directoryMember.onlineState ==
                                                                OnlineState.Online && (
                                                                <span className="offlineDot"></span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {directoryMember.isActive
                                                                ? directoryMember.practices?.map(
                                                                      (
                                                                          practice: Practice,
                                                                          index
                                                                      ) => (
                                                                          <span
                                                                              key={
                                                                                  index
                                                                              }>
                                                                              {practice.website ? (
                                                                                  <Link
                                                                                      target={
                                                                                          '_blank'
                                                                                      }
                                                                                      to={
                                                                                          '//' +
                                                                                          practice.website
                                                                                      }>
                                                                                      {
                                                                                          practice.name
                                                                                      }
                                                                                  </Link>
                                                                              ) : (
                                                                                  practice.name
                                                                              )}{' '}
                                                                              -{' '}
                                                                              {
                                                                                  practice.city
                                                                              }
                                                                              ,{' '}
                                                                              {StateAbbreviationService.getAbbreviation(
                                                                                  practice.state
                                                                              )}{' '}
                                                                              <br />
                                                                          </span>
                                                                      )
                                                                  )
                                                                : '-'}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {`${
                                                                directoryMember.veterinarySchool ??
                                                                '-'
                                                            }, ${
                                                                directoryMember.graduationYear?.toString() ??
                                                                '-'
                                                            }`}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                directoryMember.countOfPosts
                                                            }
                                                        </TableCell>
                                                        {isAdmin ? (
                                                            <TableCell align="center">
                                                                <span
                                                                    title="Access Rights"
                                                                    className="icon-hover-style"
                                                                    style={{
                                                                        marginLeft:
                                                                            '10px',
                                                                        marginRight:
                                                                            '15px',
                                                                    }}
                                                                    onClick={() =>
                                                                        editUserAccessRights(
                                                                            directoryMember
                                                                        )
                                                                    }>
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        icon={
                                                                            faEye
                                                                        }
                                                                    />
                                                                </span>
                                                            </TableCell>
                                                        ) : (
                                                            []
                                                        )}
                                                        <TableCell align="center">
                                                            {directoryMember.isActive ? (
                                                                <DirectButton
                                                                    size="small"
                                                                    clickCallback={() =>
                                                                        props.setVisibility(
                                                                            false
                                                                        )
                                                                    }
                                                                    memberId={
                                                                        directoryMember.userId
                                                                    }></DirectButton>
                                                            ) : (
                                                                []
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                </ModalBody>
                {isUserAccessRightsModalVisible && !loading ? (
                    <PocAccessManagingToolsModal
                        userId={selectedMemberUserId}
                        userAccessRights={selectedUserAccessRights}
                        updateUserPocAccessRights={updateUserAccessRights}
                        onClose={onClose}></PocAccessManagingToolsModal>
                ) : (
                    []
                )}
            </Modal>
            {getSelectedMember() &&
            (currentMemberId || Helpers.hasAccessRight(USER_ROLES.ADMIN)) &&
            isPocProfileModalVisible ? (
                <PocProfileModal
                    isVisible={isPocProfileModalVisible}
                    setVisibility={(isVisible, closePopup) => {
                        setIsPocProfileModalVisible(isVisible);
                        if (closePopup) props.setVisibility(false);
                    }}
                    currentMemberId={currentMemberId}
                    member={getSelectedMember()}></PocProfileModal>
            ) : (
                []
            )}
        </div>
    );
};

export default PosterDirectoryModal;
